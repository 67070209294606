<template>
	<v-container class="fill-height pa-0" style="flex-direction: column; justify-content: center" fluid>
		<v-card max-width="320" min-width="320" min-height="450" max-height="450" class="rounded-lg" :flat="embed" :class="embed ? 'transparent':''">
			<v-card-title class="primary white--text pa-3">
				<v-icon dark class="mr-4" size="18">mdi-account</v-icon>
				<div class="sub-title-2">{{ $tc("auth.Login.login", 1) }}</div>
				<v-spacer v-if="embed" />
				<v-icon v-if="embed" @click="$emit('close')" dark class="mr-1" size="18">mdi-close</v-icon>
			</v-card-title>
			<div class="pa-4" @keyup.enter="login()">
				<v-alert :value="error" type="error" dense text class="rounded-lg">
					{{ this.error_message }}
				</v-alert>
				<div class="sub-title-2 label--text">{{ $tc('auth.Login.username', 1) }}</div>
				<TextField
						class="mb-4"
						v-model="username"
						:clearable="true"
				/>
				<div class="sub-title-2 label--text">{{ $tc('auth.Login.password', 1) }}</div>
				<TextField
						class="mb-6"
						v-model="password"
						:type="show_pwd ? '':'password'"
						:appendIcon="show_pwd ? 'mdi-eye-off':'mdi-eye'"
						:iconClick="() => show_pwd = !show_pwd"
						:clearable="true"
				/>
				<Button
						class="mt-4"
						color="primary"
						:label="$tc('auth.Login.login', 1)"
						:disabled="username === '' || password === ''"
						:click="() => login()"
						:loading="loading"
						large
				/>
				<br />
				<br />
				<span
						@click="dialog = true"
						style="width: auto; cursor: pointer; text-decoration: underline"
						class="v-ripple paragraph primary--text"
				>
					{{ $tc('auth.Login.forgot_p', 1) }}
				</span>
			</div>
		</v-card>
		<div v-if="embed" class="sub-title-2">{{ $tc('auth.Login.not_registered', 1) }}<div @click="$emit('register')" style="display: inline; width: auto; cursor: pointer; text-decoration: underline" class="primary--text ml-2">{{ $tc('auth.Login.register', 1) }}</div></div>

		<div v-else class="sub-title-2 mt-6">{{ $tc('auth.Login.not_registered', 1) }}<router-link to="/auth/register" style="width: auto; cursor: pointer; text-decoration: underline" class="primary--text ml-2">{{ $tc('auth.Login.register', 1) }}</router-link></div>

		<v-dialog v-model="dialog" max-width="400">
			<v-card>
				<v-card-title class="primary pa-3">
					<span class="sub-title-2 white--text font-weight-regular">{{ $tc('auth.Login.p_recovery', 1) }}</span>
					<v-spacer/>
					<v-btn small icon dark @click="dialog = false ; email = ''">
						<v-icon size="20">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<div class="pa-4">
					<Label :value="$tc('auth.Login.enter_email', 1)" />
					<TextField v-model="email" />
				</div>
				<v-card-actions>
					<v-spacer />
					<Button :label="$tc('global.action.send', 1)" :click="() => sendPasswordRequest()" color="primary" iconPosition="right" iconVal="mdi-send" :iconSmall="true" :loading="loading" />
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	export default {
		name: "Login",
		props: {
			embed: {type: Boolean}
		},
		components: {
			TextField: () => import('@/components/fields/TextField.vue'),
			Label: () => import('@/components/ui/Label.vue'),
			Button: () => import('@/components/ui/Button.vue')
		},
		data() {
			return {
				dialog: false,
				error: false,
				error_message: '',
				email: '',
				username: '',
				password: '',
				loading: false,
				show_pwd: false
			}
		},
		methods: {
			sendPasswordRequest() {
				this.loading = true
				this.$wsc.post('user/forgotpassword', {"email": this.email}, () => {
					this.dialog = false
					this.$store.dispatch('snackbar/success', 'An email has been sent')
					this.loading = false
				}, () => {
					this.$store.dispatch('snackbar/error', 'Email not found')
					this.loading = false
				})
			},
			login() {
				this.loading = true

				this.$wsc.post('user/login', {username: this.username, password: this.password}, (res) => {
					this.error = false
					this.$store.dispatch('auth/loginState')
				}, (e) => {
					this.error_message = e
					this.error = true
					this.loading = false
				})
			}
		}
	}
</script>
